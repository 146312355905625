import React, { useState } from "react";
import axios from "axios";

function UpdateEvents(props) {
  const [day, setDay] = useState(props.data.day);
  const [month, setMonth] = useState(props.data.month);
  const [upeventsHead, setUpEventsHead] = useState(props.data.upeventsHead);
  const [upeventsDes, setUpEventsDes] = useState(props.data.upeventsDes);
  const [upeventsLink, setUpEventsLink] = useState(props.data.link);
  var token = sessionStorage.getItem("token");
  const updateEvents = (id) => {
    token = sessionStorage.getItem("token");
    axios
      .patch(
        "https://unesco-backend.vercel.app/upcomingevent/" + id,
        {
          day: day,
          month: month,
          upeventsHead: upeventsHead,
          upeventsDes: upeventsDes,
          link: upeventsLink,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    alert("Updated!");
    props.setUpdate(true);
  };

  return (
    <div className="modal fade" id={`upModal${props.id}`} tabindex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <h2 className="mx-auto mt-3">Update Event</h2>
          <form className="form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="upeventsDay"
                placeholder="Day"
                value={day}
                onChange={(e) => {
                  setDay(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="upeventsMonth"
                placeholder="Month"
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="upeventsHead"
                placeholder="upevents Heading"
                value={upeventsHead}
                onChange={(e) => {
                  setUpEventsHead(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="upeventsDes"
                placeholder="upevents Description"
                value={upeventsDes}
                onChange={(e) => {
                  setUpEventsDes(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="upeventsLink"
                placeholder="upevents Link (Optional)"
                value={upeventsLink}
                onChange={(e) => {
                  setUpEventsLink(e.target.value);
                }}
              />
            </div>
          </form>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                updateEvents(props.mongoid);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateEvents;
