import React from "react";
import { useState, useEffect } from "react";
import "../style/Page.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import AddEvent from "./AddEvent";
import UpdateEvents from "./UpdateEvent";
var token = sessionStorage.getItem("token");

function Upcoming() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const getEvents = async () => {
    setLoading(true);
    await axios
      .get("https://unesco-backend.vercel.app/upcomingevent")
      .then((res) => {
        const data = res.data;
        data.reverse();
        setEvents(data);
      });
    setLoading(false);
  };

  useEffect(() => {
    getEvents();
    setUpdate(false);
  }, [update]);

  const handleDelete = async (id) => {
    token = sessionStorage.getItem("token");
    if (window.confirm("Are you sure?")) {
      await axios.delete(
        "https://unesco-backend.vercel.app/upcomingevent/" + id,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );
      alert("Deleted Sucessfully!");
      setUpdate(true);
      window.location.reload();
    }
  };

  const [day, setDay] = useState();
  const [month, setMonth] = useState("");
  const [upeventsHead, setUpEventsHead] = useState("");
  const [upeventsDes, setUpEventsDes] = useState("");
  const [upeventsLink, setUpEventsLink] = useState("");

  const handleSubmit = async () => {
    token = sessionStorage.getItem("token");
    const upcoming = {
      day: day,
      month: month,
      upeventsHead: upeventsHead,
      upeventsDes: upeventsDes,
      link: upeventsLink,
    };

    if (day && month && upeventsHead && upeventsDes) {
      await axios
        .post("https://unesco-backend.vercel.app/upcomingevent", upcoming, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
          setDay("");
          setMonth("");
          setUpEventsHead("");
          setUpEventsDes("");
          setUpEventsLink("");
        });
    } else {
      alert("Fill all The Informations");
    }
    setUpdate(true);
    window.location.reload();
  };

  return (
    <div className="Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Fetching Events"
      ></LoadingScreen>

      <h1 id="heading">Upcoming</h1>

      <h3 className="subHeadings">Current Upcoming Events: </h3>

      {events.length > 0
        ? events.map((value, index) => {
            return (
              <div className="card contactCard">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {" "}
                    <b>Day And Month: </b>
                    {value.day}/{value.month}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Event Heading: </b> {value.upeventsHead}{" "}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Event Description: </b> {value.upeventsDes}{" "}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Event Link (Optional): </b> {value.link}{" "}
                  </li>
                </ul>

                <UpdateEvents
                  id={index}
                  data={value}
                  mongoid={events[index]._id}
                  setUpdate={setUpdate}
                />

                <div className="d-flex">
                  <button
                    className="btn btn-primary w-50"
                    data-bs-toggle="modal"
                    data-bs-target={`#upModal${index}`}
                    id={index}
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-danger delete w-50"
                    id={index}
                    onClick={(e) => {
                      handleDelete(events[e.target.id]._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })
        : null}

      <h3 className="subHeadings">Add Upcoming Events: </h3>
      <AddEvent
        day={day}
        month={month}
        upeventsHead={upeventsHead}
        upeventsDes={upeventsDes}
        upeventsLink={upeventsLink}
        setDay={setDay}
        setMonth={setMonth}
        setUpEventsHead={setUpEventsHead}
        setUpEventsDes={setUpEventsDes}
        setUpEventsLink={setUpEventsLink}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default Upcoming;
