import React from 'react';
import {useState, useEffect} from 'react';
import Sidemenu from './Sidemenu';
import Heading from "./Heading"
import "../style/Pannel.scss"
import News from './News';
import $ from "jquery"
import Upcoming from './Upcoming';
import Contact from './Contact';
import { removeUserSession } from '../Utils/Common';
import Mou from './Mou';
import Publication from './Publications';
import Carousel from './Carousel';
import Webinar from './Webinar';
import Gallery from './Gallery';
import StudentUnits from './StudentUnits';
import IJDA from './IJDA';
import Gn from './GN';
function Pannel(props) {
  const [option, setOption] = useState(0);
  // const user = getUser();

  // handle click event of logout button
  const handleLogout = () => {
    removeUserSession();
    props.history.push('/');
  }

  useEffect(() => {
    $(`#${option}`).addClass("active")
  }, [option]);

  return (
  <div className='Pannel'>
      <Sidemenu setOption={setOption} logout={handleLogout}/>
      {
          {
            0: <Heading/>,
            1: <News/>,
            2: <Upcoming/>,
            3: <Contact/>,
            4: <Mou/>,
            5: <Carousel/> ,
            6: <Publication/>,
            7: <Webinar/>,
            8: <Gallery/>,
            9: <StudentUnits/>,
            10: <IJDA/>,
            11: <Gn/>
          }[option]
        }
  </div>
  )
}

export default Pannel;
