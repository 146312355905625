import React from 'react';

function AddNews(props) {
  return (
    <form className="form">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsDay"
            placeholder="Day"
            value={props.day}
            onChange={(e) => {
              props.setDay(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsMonth"
            placeholder="Month"
            value={props.month}
            onChange={(e) => {
              props.setMonth(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsHead"
            placeholder="News Heading"
            value={props.newsHead}
            onChange={(e) => {
              props.setNewsHead(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsDes"
            placeholder="News Description"
            value={props.newsDes}
            onChange={(e) => {
              props.setNewsDes(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsLink"
            placeholder="News Link (Optional)"
            value={props.newsLink}
            onChange={(e) => {
              props.setNewsLink(e.target.value);
            }}
          />
        </div>
        <button
          type="button"
          className=" btn btn-primary save-button"
          onClick={props.handleSubmit}
        >
          Add
        </button>
      </form>
  );
}

export default AddNews;
