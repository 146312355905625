import React from 'react';

function AddEvent(props) {
  return(
    <form className='form'>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="eventDay"
        placeholder="Day"
        value={props.day}
        onChange={(e) => {
          props.setDay(e.target.value);
        }}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="eventMonth"
        placeholder="Month"
        value={props.month}
        onChange={(e) => {
          props.setMonth(e.target.value);
        }}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="eventHead"
        placeholder="Event Heading"
        value={props.upeventsHead}
        onChange={(e) => {
          props.setUpEventsHead(e.target.value);
        }}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="eventDes"
        placeholder="Event Description"
        value={props.upeventsDes}
        onChange={(e) => {
          props.setUpEventsDes(e.target.value);
        }}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        className="form-control"
        name="eventLink"
        placeholder="Event Link"
        value={props.upeventsLink}
        onChange={(e) => {
          props.setUpEventsLink(e.target.value);
        }}
      />
    </div>
    <button
      type="button"
      className=" btn btn-primary save-button"
      onClick={props.handleSubmit}
    >
      Add
    </button>
  </form>
  )
}

export default AddEvent;
