import React, { useState } from "react";
import axios from "axios";

function UpdateNews(props) {
  const [day, setDay] = useState(props.data.day);
  const [month, setMonth] = useState(props.data.month);
  const [newsHead, setNewsHead] = useState(props.data.noticeHead);
  const [newsDes, setNewsDes] = useState(props.data.noticeDes);
  const [newsLink, setNewsLink] = useState(props.data.link);
  var token = sessionStorage.getItem("token");

  const updNews = (id) => {
    token = sessionStorage.getItem("token");
    axios
      .patch(
        "https://unesco-backend.vercel.app/notice/" + id,
        {
          day: day,
          month: month,
          noticeHead: newsHead,
          noticeDes: newsDes,
          link: newsLink,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    alert("Updated!");
    props.setUpdate(true);
  };

  return (
    <div className="modal fade" id={`Modal${props.id}`} tabindex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <h2 className="mx-auto mt-3">Update News</h2>
          <form className="form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="newsDay"
                placeholder="Day"
                value={day}
                onChange={(e) => {
                  setDay(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="newsMonth"
                placeholder="Month"
                value={month}
                onChange={(e) => {
                  setMonth(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="newsHead"
                placeholder="News Heading"
                value={newsHead}
                onChange={(e) => {
                  setNewsHead(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="newsDes"
                placeholder="News Description"
                value={newsDes}
                onChange={(e) => {
                  setNewsDes(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="newsLink"
                placeholder="News Link (Optional)"
                value={newsLink}
                onChange={(e) => {
                  setNewsLink(e.target.value);
                }}
              />
            </div>
          </form>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                updNews(props.mongoid);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateNews;
