import React from "react";
import { useState } from "react";
import "../style/login.scss";

import axios from "axios";
import { setUserSession } from "../Utils/Common";

function Login(props) {
  const [loading, setLoading] = useState(false);
  const username = useFormInput("");
  const password = useFormInput("");
  const [error, setError] = useState(null);

  // handle button click of login form
  const handleLogin = () => {
    setError(null);
    setLoading(true);
    axios
      .post("https://unesco-backend.vercel.app/login", {
        username: username.value,
        password: password.value,
      })
      .then((response) => {
        setLoading(false);
        setUserSession(response.data.token, response.data.user);
        props.history.push("/dashboard");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401)
          setError(error.response.data.message);
        else setError("Something went wrong. Please try again later.");
      });
  };

  const handleSubmit = () => {};

  return (
    <main className="form-signin">
      <img
        src="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        alt="LBS Harda Logo"
        id="logo"
      />
      <form onSubmit={handleSubmit} className="loginForm">
        <h1 className="h3 mb-3 fw-normal">Please sign in</h1>

        <div>
          Username
          <br />
          <input
            className="loginInput"
            type="text"
            {...username}
            autoComplete="new-password"
          />
        </div>
        <div style={{ marginTop: 10 }}>
          Password
          <br />
          <input
            className="loginInput"
            type="password"
            {...password}
            autoComplete="new-password"
          />
        </div>
        {error && (
          <>
            <small style={{ color: "red" }}>{error}</small>
            <br />
          </>
        )}
        <br />
        {/* <input type="button"  /><br /> */}

        <button
          className="w-100 btn btn-lg btn-primary"
          type="button"
          value={loading ? "Loading..." : "Login"}
          onClick={handleLogin}
          disabled={loading}
        >
          Sign in
        </button>
      </form>
    </main>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

export default Login;
