import React from "react";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import "../style/gallery.scss";
import "../style/Page.scss";
import axios from "axios";

var token = sessionStorage.getItem("token");

function StudentUnits() {
  const [data, setData] = useState({
    pdf: "",
  });

  const [data2, setData2] = useState({
    series: "",
    topic: "",
  });

  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleChange = (name) => (e) => {
    const value = e.target.files[0];
    setData({ ...data, [name]: value });
  };

  // Added For Testing

  const handleChange2 = (name) => (e) => {
    const { name, value } = e.target;
    setData2({
      ...data2,
      [name]: value,
    });
  };

  // Here it end

  const handleSubmit = async () => {
    setLoading(true);
    token = sessionStorage.getItem("token");
    try {
      let formData2 = new FormData();
      formData2.append("file", data.pdf);
      formData2.append("upload_preset", "nwd6b30a");

      var obj2 = {
        method: "POST",
        body: formData2,
      };
      const res2 = await fetch(
        `https://api.cloudinary.com/v1_1/unesco-admin/raw/upload`,
        obj2
      );
      const result2 = await res2.json();

      const summaryurl = result2.url;
      const summaryid = result2.public_id;

      const sno = data2.sno;
      const unit = data2.unit;
      const unitHead = data2.unitHead;
      const university = data2.university;

      const webinars = {
        sno: sno,
        unit: unit,
        university: university,
        unitHead: unitHead,
        report: summaryurl,
        reportid: summaryid,
      };
      console.log(webinars);

      await axios
        .post("https://unesco-backend.vercel.app/studentUnit", webinars, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
        });

      setUpdate(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await fetch(`https://unesco-backend.vercel.app/studentUnit`);
      const data = await res.json();
      setUsers(data.reverse());
    };
    fetchUsers();
    setUpdate(false);
    setLoading(false);
  }, [update]);

  const handleDelete = async (id) => {
    try {
      token = sessionStorage.getItem("token");
      var obj2 = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        `https://unesco-backend.vercel.app/studentUnit/${id}`,
        obj2
      );
      if (res.ok) {
        const updatedUsers = users.filter((user) => user._id !== id);
        setUsers(updatedUsers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="galleryContainer Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Adding Student Unit"
      ></LoadingScreen>

      <h1 className="heading">Add Student Unit</h1>
      <div className="addPhoto">
        <div className="mb-3">
          <input
            className="form-control"
            type="text"
            name="sno"
            onChange={handleChange2("series")}
            placeholder="Add Sno"
          />
          <input
            className="form-control"
            type="text"
            name="unit"
            onChange={handleChange2("topic")}
            placeholder="Add Unit"
          />

          <input
            className="form-control"
            type="text"
            name="university"
            onChange={handleChange2("topic")}
            placeholder="Add Country/University"
          />
          <input
            className="form-control"
            type="text"
            name="unitHead"
            onChange={handleChange2("topic")}
            placeholder="Add Unit Head"
          />
          <input
            className="form-control"
            type="file"
            placeholder="Add Report"
            // accept="image/*"
            name="pdf"
            onChange={handleChange("pdf")}
          />
        </div>
        <div className="text-center">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>

      <div className="row">
        {users?.map((user) => (
          <div className="col-md-3 card" key={user._id}>
            {/* <p></p> */}
            {/* <p>{user.topic}</p> */}
            <div className="p-2">
              <h5>
                <a href={user.summaryurl} target="_blank">
                  {user.sno}
                </a>
              </h5>
              <h5>
                <a href={user.summaryurl} target="_blank">
                  {user.unit}
                </a>
              </h5>
              <h5>
                <a href={user.summaryurl} target="_blank">
                  {user.unitHead}
                </a>
              </h5>
              <h5>
                <a href={user.summaryurl} target="_blank">
                  {user.university}
                </a>
              </h5>
              <h5>
                <a href={user.report} target="_blank">
                  Report
                </a>
              </h5>

              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    window.confirm("Are you sure?")
                      ? handleDelete(user._id)
                      : null
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default StudentUnits;
