import React from "react";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import "../style/gallery.scss";
import "../style/Page.scss";
var token = sessionStorage.getItem("token");
function Carousel() {
  const [data, setData] = useState({
    name: "",
    image: "",
  });

  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);
  const [limit, setLimit] = useState(0);

  const handleChange = (name) => (e) => {
    const value = name === "image" ? e.target.files[0] : e.target.value;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    token = sessionStorage.getItem("token");
    if (limit < 9) {
      setLoading(true);
      try {
        let formData = new FormData();
        formData.append("image", data.image);

        var obj = {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        };
        const res = await fetch(
          `https://unesco-backend.vercel.app/corousel`,
          obj
        );
        if (res.ok) {
          setData({ name: "", image: "" });
          setUpdate(true);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    } else {
      alert("limit");
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await fetch(`https://unesco-backend.vercel.app/corousel`);
      const data = await res.json();
      setUsers(data.reverse());
      setLimit(data.length);
    };
    fetchUsers();
    setUpdate(false);
    setLoading(false);
  }, [update]);

  const handleDelete = async (id) => {
    try {
      var obj2 = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        `https://unesco-backend.vercel.app/corousel/${id}`,
        obj2
      );
      if (res.ok) {
        const updatedUsers = users.filter((user) => user._id !== id);
        setUsers(updatedUsers);
        setLimit(updatedUsers.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="galleryContainer Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Adding Image"
      ></LoadingScreen>

      <h1 className="heading">Add to Carousel</h1>
      <div className="addPhoto">
        <div className="mb-3">
          <input
            className="form-control"
            type="file"
            accept="image/*"
            name="image"
            onChange={handleChange("image")}
          />
        </div>
        <div className="text-center">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>

      <div className="row">
        {users?.map((user) => (
          <div className="col-md-3 card" key={user._id}>
            <img src={user.avatar} alt="" width={"100%"} height={200} />
            <div className="p-2">
              <h3>{user.name}</h3>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    window.confirm("Are you sure?")
                      ? handleDelete(user._id)
                      : null
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Carousel;
