import React, { useState, useEffect } from "react";
import "../style/Page.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";

var token = sessionStorage.getItem("token");
// axios.defaults.headers.post['Authorization'] = sessionStorage.getItem('token');

console.log(sessionStorage.getItem("token"));

function IJDA() {
  const [contact, setContact] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const getContact = async () => {
    setLoading(true);
    token = sessionStorage.getItem("token");
    const res = await axios.get("https://unesco-backend.vercel.app/ijda", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    const data = res.data;
    data.reverse();
    setContact(data);
    setLoading(false);
  };

  useEffect(() => {
    getContact();
    setUpdate(false);
  }, [update]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure?")) {
      axios.delete("https://unesco-backend.vercel.app/ijda/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
    }
    setUpdate(true);
  };

  return (
    <div className="Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Fetching IJDA Contacts"
      ></LoadingScreen>

      <h1 id="heading">IJDA Contact</h1>
      <h3 className="subHeadings">Latest Contacts: </h3>

      {contact.length > 0
        ? contact.map((value, index) => {
            return (
              <div className="card contactCard">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    {" "}
                    <b>Name:</b> {value.name}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Email: </b> {value.email}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Country: </b> {value.country}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Designation: </b> {value.designation}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Phone:</b> {value.phone}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Role: </b> {value.role}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Interested In: </b> {value.interest}
                  </li>
                  <li className="list-group-item">
                    {" "}
                    <b>Message: </b> {value.message}
                  </li>
                </ul>

                <button
                  className="btn btn-danger delete"
                  id={index}
                  onClick={(e) => {
                    handleDelete(contact[e.target.id]._id);
                  }}
                >
                  Delete
                </button>
              </div>
            );
          })
        : null}
    </div>
  );
}

export default IJDA;
