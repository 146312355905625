import React, { useState } from "react";
import axios from "axios";

function UpdateMou(props) {
  const [head, sethead] = useState(props.data.head);
  const [description, setdescription] = useState(props.data.description);

  var token = sessionStorage.getItem("token");

  const updNews = (id) => {
    token = sessionStorage.getItem("token");
    axios
      .patch(
        "https://unesco-backend.vercel.app/mou/" + id,
        {
          head: head,
          description: description,
          // link: newsLink
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    alert("Updated!");
    props.setUpdate(true);
  };

  return (
    <div className="modal fade" id={`Modal${props.id}`} tabindex="-1">
      <div className="modal-dialog">
        <div className="modal-content">
          <h2 className="mx-auto mt-3">Update MOU</h2>
          <form className="form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="head"
                placeholder="Mou Heading"
                value={head}
                onChange={(e) => {
                  sethead(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="description"
                placeholder="Mou Description"
                value={description}
                onChange={(e) => {
                  setdescription(e.target.value);
                }}
              />
            </div>
          </form>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={() => {
                updNews(props.mongoid);
              }}
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpdateMou;
