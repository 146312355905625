import React from "react";
import { useEffect, useState } from "react";
import LoadingScreen from "react-loading-screen";
import "../style/gallery.scss";
import "../style/Page.scss";
import axios from "axios";

var token = sessionStorage.getItem("token");

function Webinar() {
  const [data, setData] = useState({
    video: "",
    pdf: "",
    // series: "",
    // topic: ""
  });

  const [data2, setData2] = useState({
    series: "",
    topic: "",
  });

  const [users, setUsers] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const handleChange = (name) => (e) => {
    // const {name, value} = e.target;
    const value = e.target.files[0];
    setData({ ...data, [name]: value });
  };

  // Added For Testing

  const handleChange2 = (name) => (e) => {
    const { name, value } = e.target;
    setData2({
      ...data2,
      [name]: value,
    });
  };

  // Here it end

  const handleSubmit = async () => {
    setLoading(true);
    token = sessionStorage.getItem("token");
    try {
      let formData = new FormData();
      let formData2 = new FormData();
      formData.append("file", data.video);
      formData2.append("file", data.pdf);
      formData.append("upload_preset", "nwd6b30a");
      formData2.append("upload_preset", "nwd6b30a");

      var obj = {
        method: "POST",
        body: formData,
      };

      var obj2 = {
        method: "POST",
        body: formData2,
      };
      const res = await fetch(
        `https://api.cloudinary.com/v1_1/unesco-admin/video/upload`,
        obj
      );
      const res2 = await fetch(
        `https://api.cloudinary.com/v1_1/unesco-admin/raw/upload`,
        obj2
      );
      const result1 = await res.json();
      const result2 = await res2.json();

      const videourl = result1.url;
      const videoid = result1.public_id;
      const summaryurl = result2.url;
      const summaryid = result2.public_id;

      const series = data2.series;
      const topic = data2.topic;
      const webinars = {
        series: series,
        topic: topic,
        videourl: videourl,
        videoid: videoid,
        summaryurl: summaryurl,
        summaryid: summaryid,
      };
      console.log(webinars);

      await axios
        .post("https://unesco-backend.vercel.app/webinar", webinars, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
        });

      setUpdate(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const res = await fetch(`https://unesco-backend.vercel.app/webinar`);
      const data = await res.json();
      setUsers(data.reverse());
    };
    fetchUsers();
    setUpdate(false);
    setLoading(false);
  }, [update]);

  const handleDelete = async (id) => {
    try {
      token = sessionStorage.getItem("token");
      var obj2 = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer " + token,
        },
      };
      const res = await fetch(
        `https://unesco-backend.vercel.app/webinar/${id}`,
        obj2
      );
      if (res.ok) {
        const updatedUsers = users.filter((user) => user._id !== id);
        setUsers(updatedUsers);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="galleryContainer Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Adding Webinar"
      ></LoadingScreen>

      <h1 className="heading">Add Webinar</h1>
      <div className="addPhoto">
        <div className="mb-3">
          <input
            className="form-control"
            type="text"
            name="series"
            onChange={handleChange2("series")}
            placeholder="Add Series"
          />
          <input
            className="form-control"
            type="text"
            name="topic"
            onChange={handleChange2("topic")}
            placeholder="Add Topic"
          />
          <label htmlFor="video">Video</label>
          <input
            className="form-control"
            type="file"
            // accept="image/*"
            name="video"
            onChange={handleChange("video")}
          />
          <label htmlFor="pdf">PDF</label>
          <input
            className="form-control"
            type="file"
            // accept="image/*"
            name="pdf"
            onChange={handleChange("pdf")}
          />
        </div>
        <div className="text-center">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Add
          </button>
        </div>
      </div>

      <div className="row">
        {users?.map((user) => (
          <div className="col-md-3 card" key={user._id}>
            {/* <video src={user.videourl} alt="" width={"100%"} height={200} /> */}
            <video width="100%" height="200" controls>
              <source src={user.videourl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            {/* <p></p> */}
            {/* <p>{user.topic}</p> */}
            <div className="p-2">
              <h5>
                <a href={user.summaryurl} target="_blank">
                  {user.topic}
                </a>
              </h5>
              <div className="d-flex justify-content-between align-items-center">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() =>
                    window.confirm("Are you sure?")
                      ? handleDelete(user._id)
                      : null
                  }
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Webinar;
