import React from "react";
import { useState, useEffect } from "react";
import "../style/Page.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
// import AddNews from "./AddNews";
import UpdateMou from "./UpdateMou";
import AddMou from "./AddMou";
var token = sessionStorage.getItem("token");

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const getNews = async () => {
    setLoading(true);
    await axios.get("https://unesco-backend.vercel.app/mou").then((res) => {
      const data = res.data;
      data.reverse();
      setNews(data);
    });
    setLoading(false);
  };

  useEffect(() => {
    getNews();
    setUpdate(false);
  }, [update]);

  const handleDelete = async (id) => {
    token = sessionStorage.getItem("token");
    if (window.confirm("Are you sure?")) {
      await axios.delete("https://unesco-backend.vercel.app/mou/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      alert("Deleted Sucessfully!");
      setUpdate(true);
      window.history.go();
    }
  };

  const [head, sethead] = useState("");
  const [description, setdescription] = useState("");

  const handleSubmit = async () => {
    token = sessionStorage.getItem("token");
    const news = {
      head: head,
      description: description,
    };

    if (head && description) {
      await axios
        .post("https://unesco-backend.vercel.app/mou", news, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
          sethead("");
          setdescription("");
        });
    } else {
      alert("Fill all The Informations");
    }
    setUpdate(true);
    window.history.go();
  };

  return (
    <div className="Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Fetching MOU's"
      ></LoadingScreen>

      <h1 id="heading">MOU's</h1>
      <h3 className="subHeadings">Current Mou's: </h3>

      {news.length > 0
        ? news.map((value, index) => {
            return (
              <div className="card contactCard">
                <ul className="list-group list-group-flush">
                  {/* <li className="list-group-item">
                    <b>Day And Month: </b>
                    {value.day}/{value.month}
                  </li> */}
                  <li className="list-group-item">
                    <b>MOU Heading: </b> {value.head}
                  </li>
                  <li className="list-group-item">
                    <b>MOU Description: </b> {value.description}
                  </li>
                  {/* <li className="list-group-item">
                    <b>News Link (Optional): </b> {value.link}
                  </li> */}
                </ul>

                <UpdateMou
                  id={index}
                  data={value}
                  mongoid={news[index]._id}
                  setUpdate={setUpdate}
                />

                <div className="d-flex">
                  <button
                    className="btn btn-primary w-50"
                    data-bs-toggle="modal"
                    data-bs-target={`#Modal${index}`}
                    id={index}
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-danger delete w-50"
                    id={index}
                    onClick={(e) => {
                      handleDelete(news[e.target.id]._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })
        : null}

      <h3 className="subHeadings">Add MOU: </h3>
      <AddMou
        head={head}
        description={description}
        sethead={sethead}
        setdescription={setdescription}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default News;
