import React from "react";
import "../style/SideMenu.scss";
import $ from "jquery";
import { Link } from "react-router-dom";

function Sidemenu(props) {
  function handleClick(opt) {
    $(".active").removeClass("active");
    props.setOption(opt);
  }
  return (
    <div className="SideMenu">
      <div
        className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark"
        style={{ height: "100vh" }}
      >
        <Link
          to="/login"
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
        >
          <span className="fs-4">UNESCO Chair in Bioethics Admin</span>
        </Link>
        <hr />
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <button
              onClick={() => {
                handleClick(0);
              }}
              className="nav-link text-white"
              id="0"
            >
              <i className="fas fa-heading"></i> Heading
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(1);
              }}
              className="nav-link text-white"
              id="1"
            >
              <i className="fas fa-newspaper"></i> News
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(2);
              }}
              className="nav-link text-white"
              id="2"
            >
              <i className="fas fa-calendar-alt"></i> Upcoming Event
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(3);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="far fa-address-book"></i> Contacts
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(4);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="fas fa-file-signature"></i> MOU
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(5);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="far fa-image"></i> Carousel
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(6);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="far fa-file-pdf"></i> Publication
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(7);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="fa fa-video"></i> Webinar
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(8);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="fa fa-film"></i> Gallery
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(9);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="fa-brands fa-unity"></i> Student Units
            </button>
          </li>
          <li>
            <button
              onClick={() => {
                handleClick(10);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="far fa-address-book"></i> IJDA Contacts
            </button>
          </li>
          
          <li>
            <button
              onClick={() => {
                handleClick(11);
              }}
              className="nav-link text-white"
              id="3"
            >
              <i className="far fa-address-book"></i> Global Network Contacts
            </button>
          </li>
        </ul>
        <hr />
        <div className="dropdown">
          <a
            className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
            id="dropdownUser1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            href
          >
            <img
              src="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
              alt=""
              width="32"
              height="32"
              className="rounded-circle me-2"
            />
            <strong>Admin</strong>
          </a>
          <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
            <li>
              <button className="dropdown-item" onClick={props.logout}>
                Sign out
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidemenu;
