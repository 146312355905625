import React from "react";
import { useState, useEffect } from "react";
import "../style/Page.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";
import AddNews from "./AddNews";
import UpdateNews from "./UpdateNews";
var token = sessionStorage.getItem("token");

function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(false);

  const getNews = async () => {
    setLoading(true);
    await axios.get("https://unesco-backend.vercel.app/notice").then((res) => {
      const data = res.data;
      data.reverse();
      setNews(data);
      console.log(data);
    });

    setLoading(false);
    setUpdate(false);
  };

  useEffect(() => {
    getNews();
  }, [update]);

  const handleDelete = async (id) => {
    token = sessionStorage.getItem("token");
    if (window.confirm("Are you sure?")) {
      await axios.delete("https://unesco-backend.vercel.app/notice/" + id, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      alert("Deleted Sucessfully!");
      setUpdate(true);
      window.location.reload();
    }
  };

  const [day, setDay] = useState();
  const [month, setMonth] = useState("");
  const [newsHead, setNewsHead] = useState("");
  const [newsDes, setNewsDes] = useState("");
  const [newsLink, setNewsLink] = useState("");

  const handleSubmit = async () => {
    token = sessionStorage.getItem("token");
    const news = {
      day: day,
      month: month,
      noticeHead: newsHead,
      noticeDes: newsDes,
      link: newsLink,
    };

    if (day && month && newsHead && newsDes) {
      await axios
        .post("https://unesco-backend.vercel.app/notice", news, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then((res) => {
          console.log(res);
          setDay("");
          setMonth("");
          setNewsHead("");
          setNewsDes("");
          setNewsLink("");
        });
    } else {
      alert("Fill all The Informations");
    }
    setUpdate(true);
    window.location.reload();
  };

  return (
    <div className="Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Fetching News"
      ></LoadingScreen>

      <h1 id="heading">News</h1>
      <h3 className="subHeadings">Current News: </h3>

      {news.length > 0
        ? news.map((value, index) => {
            return (
              <div className="card contactCard">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item">
                    <b>Day And Month: </b>
                    {value.day}/{value.month}
                  </li>
                  <li className="list-group-item">
                    <b>News Heading: </b> {value.noticeHead}
                  </li>
                  <li className="list-group-item">
                    <b>News Description: </b> {value.noticeDes}
                  </li>
                  <li className="list-group-item">
                    <b>News Link (Optional): </b> {value.link}
                  </li>
                </ul>

                <UpdateNews
                  id={index}
                  data={value}
                  mongoid={news[index]._id}
                  setUpdate={setUpdate}
                />

                <div className="d-flex">
                  <button
                    className="btn btn-primary w-50"
                    data-bs-toggle="modal"
                    id={index}
                    data-bs-target={`#Modal${index}`}
                  >
                    Update
                  </button>

                  <button
                    className="btn btn-danger delete w-50"
                    id={index}
                    onClick={(e) => {
                      handleDelete(news[e.target.id]._id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })
        : null}

      <h3 className="subHeadings">Add News: </h3>
      <AddNews
        day={day}
        month={month}
        newsHead={newsHead}
        newsDes={newsDes}
        newsLink={newsLink}
        setDay={setDay}
        setMonth={setMonth}
        setNewsHead={setNewsHead}
        setNewsDes={setNewsDes}
        setNewsLink={setNewsLink}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default News;
