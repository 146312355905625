import React from 'react';

function AddMou(props) {
  return (
    <form className="form">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="head"
            placeholder="MOU Heading"
            value={props.head}
            onChange={(e) => {
              props.sethead(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="description"
            placeholder="MOU Description"
            value={props.description}
            onChange={(e) => {
              props.setdescription(e.target.value);
            }}
          />
        </div>
        {/* <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="newsLink"
            placeholder="News Link (Optional)"
            value={props.newsLink}
            onChange={(e) => {
              props.setNewsLink(e.target.value);
            }}
          />
        </div> */}
        <button
          type="button"
          className=" btn btn-primary save-button"
          onClick={props.handleSubmit}
        >
          Add
        </button>
      </form>
  );
}

export default AddMou;
