import React from "react";
import { useState, useEffect } from "react";
import "../style/Page.scss";
import axios from "axios";
import LoadingScreen from "react-loading-screen";

var token = sessionStorage.getItem("token");

function Heading() {
  const [announcements, setAnnouncements] = useState([]);
  const [loading, setLoading] = useState(true);
  const [heading, setHeading] = useState("");
  const [update, setUpdate] = useState(false);

  const getAnnouncements = async () => {
    setLoading(true);
    await axios.get("https://unesco-backend.vercel.app/news").then((res) => {
      const data = res.data;
      setAnnouncements(data[0].newshead);
    });
    setLoading(false);
    setUpdate(false);
  };

  useEffect(() => {
    getAnnouncements();
  }, [update]);

  function handleChange(e) {
    let text = e.target.value;
    setHeading(text);
  }

  const saveHeading = () => {
    token = sessionStorage.getItem("token");
    axios
      .patch(
        `https://unesco-backend.vercel.app/news`,
        {
          newshead: heading,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });

    alert("Updated!");
    setUpdate(true);
  };

  return (
    <div className="Page">
      <LoadingScreen
        loading={loading}
        bgColor="#ffffff"
        spinnerColor="#01471B"
        textColor="#01471B"
        logoSrc="https://res.cloudinary.com/unesco-admin/image/upload/v1645289463/image003-removebg-preview_dkzzc8.png"
        text="Fetching Heading"
      ></LoadingScreen>

      <h1 id="heading">Heading</h1>

      <h3 className="subHeadings">Current Heading: </h3>

      <div className="form-floating" id="headCont">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id="upHead"
          disabled
          value={announcements}
        ></textarea>
      </div>

      <h3 className="subHeadings">Update Heading: </h3>

      <div className="form-floating" id="headCont">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id="upHead"
          onChange={(e) => {
            handleChange(e);
          }}
        ></textarea>
        <label htmlFor="upHead">Heading</label>
      </div>

      <button
        type="button"
        className=" btn btn-primary save-button"
        onClick={saveHeading}
      >
        Update
      </button>
    </div>
  );
}

export default Heading;
